<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form ref="form" @submit.prevent="submitForm">
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    v-model="form.searchKeyword"
                    :disabled="datatable.loading"
                    :label="$t('SHARED.KEYWORD')"
                    :hint="$t('PROMOTION.SEARCH.KEYWORD_HINT')"
                    prepend-icon="mdi-file-document-box-search-outline"
                  ></v-text-field>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.isShow"
                    :disabled="datatable.loading"
                    :items="form.isShowItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('PROMOTION.SEARCH.IS_SHOW')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH')
                    "
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
                <div class="col-3">
                  <v-autocomplete
                    v-model="form.inActiveStatus"
                    :disabled="datatable.loading"
                    :items="form.inActiveStatusItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('PROMOTION.SEARCH.IN_ACTIVE_STATUS')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH')
                    "
                    prepend-icon="mdi-database-search"
                    return-object
                    clearable
                  ></v-autocomplete>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <v-btn
                    :disabled="datatable.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon v-if="!datatable.loading" left
                      >mdi-database-search</v-icon
                    >
                    <v-icon v-if="datatable.loading" left
                      >fa fa-spinner fa-spin</v-icon
                    >
                    {{ $t("SHARED.SEARCH_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="datatable.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                  <!-- <v-btn
                    :disabled="datatable.loading"
                    color="warning"
                    class="mr-4"
                    tile
                    @click.prevent="Export"
                  >
                    <v-icon v-if="!datatable.loading" left
                      >fas fa-file-export</v-icon
                    >
                    <v-icon v-if="datatable.loading" left
                      >fa fa-spinner fa-spin</v-icon
                    >
                    {{ $t("SHARED.EXPORT_BUTTON") }}
                  </v-btn> -->
                </div>
              </div>
            </v-form>

            <v-divider class="my-4"></v-divider>

            <v-subheader>
              <v-icon left>mdi-table-search</v-icon>
              {{ $t("SHARED.SEARCH_RESULT") }}
              <v-spacer></v-spacer>
              <v-btn
                color="info"
                class="mr-4"
                type="button"
                tile
                to="/Promotion/Add"
                target="_self"
              >
                <v-icon left>add_circle_outline</v-icon>
                {{ $t("MENU.PROMOTION.ADD") }}
              </v-btn>
            </v-subheader>

            <v-data-table
              :headers="datatable.headers"
              :items="datatable.items"
              :loading="datatable.loading"
              :options.sync="datatable.options"
              :server-items-length="datatable.total"
              :footer-props="{
                'items-per-page-options': [30, 50, 100],
              }"
              multi-sort
            >
              <template v-slot:item.isShow="{ item }">
                <v-icon 
                  :class="getIsShowClass(item.isShow)">
                  {{ getIsShowIcon(item.isShow) }}
                </v-icon>
              </template>

              <template v-slot:item.inActiveStatus="{ item }">
                <v-btn
                  :color="getInActiveStatusColor(item.inActiveStatus)"
                    class="mr-4"
                    tile
                  @click="toggleItem(item.promotionId, item.inActiveStatus)"
                >
                  <v-icon left>{{ getInActiveStatusIcon(item.inActiveStatus) }}</v-icon>
                  {{ getInActiveStatusText(item.inActiveStatus) }}
                </v-btn>
              </template>

              <template v-slot:item.promotionId="{ item }">
                <v-icon
                  medium
                  class="mr-2"
                  @click="detailItem(item.promotionId)"
                >
                  mdi-view-list
                </v-icon>  
                <v-icon
                  medium
                  class="mr-2"
                  @click="uploadExel(item.promotionId)"
                >
                mdi-file-excel
                </v-icon>
                <v-icon
                  medium
                  class="mr-2"
                  @click="editItem(item.promotionId)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon v-if = "item.promotionCode != 'BirthDate'"
                  medium
                  @click="deleteItem(item.promotionId)"
                >
                  mdi-delete
                </v-icon>   
   
              </template>

              <template v-slot:item.order="props">
                <v-edit-dialog
                  :return-value.sync="props.item.order" 
                  large
                  @save="saveEditOrder(props.item.promotionId, props.item.order)"
                  @cancel="cancelEditOrder"
                  @open="openEditOrder"
                > {{ props.item.order }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.order"
                      :label="$t('PROMOTION.SEARCH.ORDER')"
                      :rules="datatable.orderRules"
                      type="number"
                      min="1"
                      required
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.isPushNoti="{ item }">
                <v-btn
                  v-if="item.isPushNoti != null"
                  :color="getInActiveStatusColor(item.isPushNoti)"
                    class="mr-4"
                    tile
                  @click="pushNotiItem(item.promotionId, item.isPushNoti, item.startDateString, item.endDateString)"
                >
                  <v-icon left>{{ getIsPushNotiIcon(item.isPushNoti) }}</v-icon>
                  {{ getIsPushNotiText(item.isPushNoti) }}
                </v-btn>
              </template>

            </v-data-table>

            <v-dialog
              v-model="datatable.loading"
              persistent
              width="300"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                >
                <v-card-text>
                  <p>
                    {{ $t("SHARED.PROCESSING") }}
                  </p>
                  <v-progress-linear
                    indeterminate
                    color="amber lighten-3"
                    class="mb-3"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-dialog 
              v-model="datatable.toggleDialog" 
              persistent  
              max-width="300"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.TOGGLE_DIALOG_HEADER") }}
                </v-card-title>
                <v-card-text>
                    {{ datatable.toggleDialogText }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="datatable.toggleDialog = false"
                    >{{ $t("SHARED.CANCEL_BUTTON") }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="confirmToggleItem()"
                    >{{ $t("SHARED.CONFIRM_BUTTON") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog 
              v-model="datatable.deleteDialog" 
              persistent  
              max-width="300"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.DELETE_DIALOG_HEADER") }}
                </v-card-title>
                <v-card-text>
                    {{ $t("SHARED.CONFIRM_DELETE_DIALOG_TEXT") }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="datatable.deleteDialog = false"
                    >{{ $t("SHARED.CANCEL_BUTTON") }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="confirmDeleteItem()"
                    >{{ $t("SHARED.CONFIRM_BUTTON") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="datatable.dialog" persistent max-width="300">
              <v-card>
                <v-card-title class="headline">
                  {{ datatable.dialogHeader }}
                </v-card-title>
                <v-card-text>
                    {{ datatable.dialogText }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="closeDialog"
                    >{{ $t("SHARED.CLOSE_BUTTON") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog 
              v-model="datatable.pushNotiDialog" 
              persistent  
              max-width="500"
            >
              <v-card>
                <v-card-title class="headline">
                  {{ $t("SHARED.PUSH_NOTI_DIALOG_HEADER") }}
                </v-card-title>
                <v-card-text>
                    {{ datatable.pushNotiDialogText }}
                    <v-container
                      v-if="!datatable.isPushNoti"
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-menu
                            ref="startDateMenu"
                            v-model="form.startDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="5"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="computedStartDateFormatted"
                                :label="$t('SHARED.PUSH_NOTI_DATE')"
                                :rules="form.startDateRules"
                                hint="DD/MM/YYYY format"
                                persistent-hint
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model="form.startDate" 
                              scrollable
                              :max="form.maxStartDate"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="form.startDateMenu = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.startDateMenu.save(form.startDate)">OK</v-btn>
                            </v-date-picker>     
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            ref="startTimeMenu"
                            v-model="form.startTimeMenu"
                            :close-on-content-click="false"
                            :nudge-right="5"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="form.startTime"
                                :label="$t('SHARED.PUSH_NOTI_TIME')"
                                :rules="form.startTimeRules"
                                hint="HH:MM format"
                                persistent-hint
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-time-picker 
                              v-model="form.startTime" 
                              format="24hr" 
                              scrollable
                              :disabled="form.loading"
                            >
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="form.startTimeMenu = false">Cancel</v-btn>
                              <v-btn text color="primary" @click="$refs.startTimeMenu.save(form.startTime)">OK</v-btn>
                            </v-time-picker>     
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="datatable.pushNotiDialog = false"
                    >{{ $t("SHARED.CANCEL_BUTTON") }}
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="confirmPushNotiItem()"
                    >{{ $t("SHARED.CONFIRM_BUTTON") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import JwtService from "@/common/jwt.service";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      form: {
        searchKeyword: "",
        isShow: null,
        isShowItems: [
          {
            text: this.$t("SYS_VARIABLE.IS_SHOW_YES"),
            value: true,
          },
          {
            text: this.$t("SYS_VARIABLE.IS_SHOW_NO"),
            value: false,
          },
        ],
        inActiveStatus: null,
        inActiveStatusItems: [
          {
            text: this.$t("SYS_VARIABLE.IN_ACTIVE_STATUS_YES"),
            value: false,
          },
          {
            text: this.$t("SYS_VARIABLE.IN_ACTIVE_STATUS_NO"),
            value: true,
          },
        ],
        startDateMenu: false,
        startDate: "",
        endDate: "",
        startDateFormatted: "",
        maxStartDate: "",
        startTime: "00:00",
        startTimeMenu: false,
        description: "",
        order: "",
      },
      datatable: {
        headers: [
          {
            text: this.$t("PROMOTION.SEARCH.NAME"),
            value: "name",
            align: "center",
          },
          {
            text: this.$t("PROMOTION.SEARCH.DESCRIPTION"),
            value: "description",
          },
          {
            text: this.$t("PROMOTION.SEARCH.ORDER"),
            value: "order",
          },
          {
            text: this.$t("PROMOTION.SEARCH.START_DATE"),
            value: "startDateString",
            align: "center",
          },
          {
            text: this.$t("PROMOTION.SEARCH.END_DATE"),
            value: "endDateString",
            align: "center",
          },
          {
            text: this.$t("PROMOTION.SEARCH.IS_SHOW"),
            value: "isShow",
            align: "center",
          },
          {
            text: this.$t("PROMOTION.SEARCH.IN_ACTIVE_STATUS"),
            value: "inActiveStatus",
            align: "center",
          },
          {
            text: this.$t("PROMOTION.SEARCH.IS_PUSH_NOTI"),
            value: "isPushNoti",
            align: "center",
          },
          {
            text: this.$t("PROMOTION.SEARCH.ACTION"),
            value: "promotionId",
            align: "center",
            sortable: false,
            width: "10%"
          },
        ],
        items: [],
        total: 0,
        loading: true,
        options: {
          sortBy: ["name"],
          sortDesc: [false],
          itemsPerPage: 30,
        },
        toggleDialog: false,
        toggleDialogText : null,
        toggleDialogId : null,
        deleteDialog: false,
        deleteDialogId : null,
        dialog: false,
        dialogText: null,
        dialogHeader: null,
        orderRules: [
          (v)  => !!v || this.$t("PROMOTION.ADD_EDIT.ORDER_VALIDATION"),
          (v) => v > 0 || this.$t("PROMOTION.ADD_EDIT.ORDER_GT_0"),
        ],
        pushNotiDialog: false,
        pushNotiDialogText : null,
        pushNotiDialogId : null,
        isPushNoti : null,
      },
    };
  },
  methods: {
    submitForm() {
      this.getDataFromApi().then((data) => {
        this.datatable.total = data.total;
        this.datatable.items = data.items;
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.submitForm();
    },
    getDataFromApi() {
      this.datatable.loading = true;
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/Promotion/Search", {
          GroupBy: this.datatable.options.groupBy,
          GroupDesc: this.datatable.options.groupDesc,
          ItemsPerPage: this.datatable.options.itemsPerPage,
          Page: this.datatable.options.page,
          SortBy: this.datatable.options.sortBy,
          SortDesc: this.datatable.options.sortDesc,
          SearchKeyword: this.form.searchKeyword,
          IsShow: this.form.isShow
            ? this.form.isShow.value
            : null,
          InActiveStatus: this.form.inActiveStatus
            ? this.form.inActiveStatus.value
            : null,    
        })
          .then(({ data }) => {
            for(var i = 0; i < data.items.length; i++)
            {
              if(data.items[i].description != null)
              {
                data.items[i].description = data.items[i].description.length < 100 ? data.items[i].description : data.items[i].description.substring(0,150) + "..." 
              }
            }
            resolve({
              items: data.items,
              total: data.total,
            });
          })
          .finally(() => {
            this.datatable.loading = false;
          });
      });
    },
    getIsShowClass(isShow){
      if (isShow) 
        return 'text-success';
      else 
        return 'text-danger';
    },
    getIsShowIcon(isShow){
      if (isShow) 
        return 'check_circle';
      else 
        return 'cancel';
    },
    getInActiveStatusColor(inActiveStatus) {
      if (inActiveStatus) 
        return 'error';
      else 
        return 'success';
    },
    getInActiveStatusText(inActiveStatus) {
      if (inActiveStatus) 
        return this.$t("SYS_VARIABLE.IN_ACTIVE_STATUS_NO");
      else 
        return this.$t("SYS_VARIABLE.IN_ACTIVE_STATUS_YES");
    },
    getInActiveStatusIcon(inActiveStatus) {
      if (inActiveStatus) 
        return 'pause';
      else 
        return 'play_arrow';
    },
    editItem(id){
      this.$router.push({ name: "EditPromotion", params: { id } });
    },
    uploadExel(id){
      this.$router.push({ name: "UploadExcelPromotion", params: { id } });
    },
    detailItem(id){
      this.$router.push({ name: "DetailCoupon", params: { id } });
    },
    toggleItem(id,inActiveStatus){
      this.datatable.toggleDialog = true;
      this.datatable.toggleDialogId = id;
      if (inActiveStatus) 
        this.datatable.toggleDialogText = this.$t("SHARED.TOGGLE_OPEN_DIALOG_TEXT");
      else 
        this.datatable.toggleDialogText =  this.$t("SHARED.TOGGLE_CLOSE_DIALOG_TEXT");
    },
    confirmToggleItem(){
      this.datatable.dialogHeader = this.$t("SHARED.TOGGLE_RESULT_DIALOG_HEADER");
      ApiService.setHeader();
      ApiService.update("/Api/Promotion", this.datatable.toggleDialogId +"/Toggle")
        .then(({ data }) => {
          //console.log(data);
          this.datatable.dialog = true;
          if (data.message) {
            this.datatable.dialogText = data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        })
        .catch(({ response }) => {
          //console.log(response);
          this.datatable.dialog = true;
          if (response.data) {
            this.datatable.dialogText = response.data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        });

      this.datatable.toggleDialogText = null;
      this.datatable.toggleDialogId = null;
      this.datatable.toggleDialog = false;
    },
    closeDialog() {
      this.datatable.dialog = false;
      this.datatable.dialogText = null;
      this.datatable.dialogHeader = null;
      this.submitForm();
    },
    deleteItem(id){
      this.datatable.deleteDialog = true;
      this.datatable.deleteDialogId = id;
    },
    confirmDeleteItem(){
      this.datatable.dialogHeader = this.$t("SHARED.DELETE_RESULT_DIALOG_HEADER");
      ApiService.setHeader();
      ApiService.update("/Api/Promotion", this.datatable.deleteDialogId +"/Delete")
        .then(({ data }) => {
          //console.log(data);
          this.datatable.dialog = true;
          if (data.message) {
            this.datatable.dialogText = data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        })
        .catch(({ response }) => {
          //console.log(response);
          this.datatable.dialog = true;
          if (response.data) {
            this.datatable.dialogText = response.data.message;
          } else {
            this.datatable.dialogText = "Unknow error occurs";
          }
        });

      this.datatable.deleteDialogId = null;
      this.datatable.deleteDialog = false;
    },
    saveEditOrder(id, text) {
      if(text == null || text == ""){
        this.datatable.dialog = true;
        this.datatable.dialogHeader = this.$t("_MENU.SEARCH.ORDER_RESULT_DIALOG_HEADER");
        this.datatable.dialogText = this.$t("_MENU.ADD_EDIT.ORDER_VALIDATION");
      }
      else{  
        ApiService.setHeader();
        ApiService.put("/Api/Promotion/EditOrder", 
          {
            PromotionId: id,
            Order: Number(text)
          })
          .then(({ data }) => {
            //console.log(data);
            this.datatable.dialog = true;
            this.datatable.dialogHeader = this.$t("PROMOTION.SEARCH.ORDER_RESULT_DIALOG_HEADER");
            if (data.message) {
              this.datatable.dialogText = data.message;
            } else {
              this.datatable.dialogText = "Unknow error occurs";
            }
          })
          .catch(({ response }) => {
            //console.log(response);
            this.datatable.dialog = true;
            if (response.data) {
              this.datatable.dialogText = response.data.message;
            } else {
              this.datatable.dialogText = "Unknow error occurs";
            }
          });   
      }
    },
    cancelEditOrder() {
      //do something
      //console.log('Canceled')
    },
    openEditOrder() {
      //do something
      //console.log('opened')
    },
    closeEditOrder() {
      //console.log('Dialog closed')
      this.datatable.dialog = false;
      this.datatable.dialogText = null;
      this.datatable.dialogHeader = null;
    },
    pushNotiItem(id, isPushNoti, startDate, endDate){
      this.datatable.pushNotiDialog = true;
      this.datatable.pushNotiDialogId = id;

      let st = startDate.split(" ");
      this.form.startDateFormatted = st[0];
      this.form.startTime = st[1].substring(0, 5);
      this.form.startDate = this.parseDate(this.form.startDateFormatted);
      let e = endDate.split(" ");
      this.form.maxStartDate = this.parseDate(e[0])
      this.datatable.isPushNoti = isPushNoti;
      if (!isPushNoti) 
        this.datatable.pushNotiDialogText = this.$t("SHARED.PUSH_NOTI_DIALOG_TEXT");
      else 
        this.datatable.pushNotiDialogText = this.$t("SHARED.PUSH_NOTI_CANCEL_DIALOG_TEXT");
    },
    confirmPushNotiItem(){
      this.datatable.dialogHeader = this.$t("SHARED.PUSH_NOTI_RESULT_DIALOG_HEADER");
      if(!this.datatable.isPushNoti){
        this.datatable.loading = true;
        ApiService.setHeader();
        ApiService.post("/Api/PushNotification",
          {
            Id: this.datatable.pushNotiDialogId + "" ,
            Type: "PROMOTION",
            StartDateString: this.form.startDateFormatted,
            StartTimeString: this.form.startTime,
          })
          .then(({ data }) => {
            //console.log(data);
            this.datatable.dialog = true;
            if (data.message) {
              this.datatable.dialogText = data.message;
            } else {
              this.datatable.dialogText = "Unknow error occurs";
            }
          })
          .catch(({ response }) => {
            //console.log(response);
            this.datatable.dialog = true;
            if (response.data) {
              this.datatable.dialogText = response.data.message;
            } else {
              this.datatable.dialogText = "Unknow error occurs";
            }
          })
          .finally(() => {
            this.datatable.loading = false;
          });
      }
      else{
        //canceled
        this.datatable.loading = true;
        ApiService.setHeader();
        ApiService.post("/Api/PushNotification/Canceled",
          {
            NotificationLink: this.datatable.pushNotiDialogId + "" ,
            NotificationType: "PROMOTION",
          })
          .then(({ data }) => {
            //console.log(data);
            this.datatable.dialog = true;
            if (data.message) {
              this.datatable.dialogText = data.message;
            } else {
              this.datatable.dialogText = "Unknow error occurs";
            }
          })
          .catch(({ response }) => {
            //console.log(response);
            this.datatable.dialog = true;
            if (response.data) {
              this.datatable.dialogText = response.data.message;
            } else {
              this.datatable.dialogText = "Unknow error occurs";
            }
          })
          .finally(() => {
            this.datatable.loading = false;
          });
      }

      this.datatable.pushNotiDialogText = null;
      this.datatable.pushNotiDialogId = null;
      this.datatable.pushNotiDialog = false;
      this.datatable.isPushNoti = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      //console.log("parseDate :" + date);
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getIsPushNotiText(isPushNoti) {
      //console.log(isPushNoti);
      if (isPushNoti) 
        return this.$t("SYS_VARIABLE.CANCEL_PUSH_NOTI");
      else
        return this.$t("SYS_VARIABLE.PUSH_NOTI");
    },
    getIsPushNotiIcon(isPushNoti) {
      //console.log(isPushNoti);
      if (isPushNoti) 
        return 'fas fa-window-close';
      else
        return 'fas fa-paper-plane';
    },
    cancelPushNotiItem(id){
      this.datatable.pushNotiDialog = true;
      this.datatable.pushNotiDialogId = id;
      this.datatable.pushNotiDialogText = this.$t("SHARED.PUSH_NOTI_CANCEL_DIALOG_TEXT");
    },
    Export() {
      var SortBy = "";
      var SortDesc = "";

        this.datatable.options.sortBy.forEach((val) => {
          SortBy += '&SortBy='+val;
        });
        this.datatable.options.sortDesc.forEach((val) => {
          SortDesc += '&SortDesc='+val;
        });
        if (this.form.isShow == null) this.form.isShow = ""
        if (this.form.inActiveStatus == null) this.form.inActiveStatus = ""
      window.open(
        "/Files/Export/Promotion?searchKeyword=" + this.form.searchKeyword +
          "&isShow=" + this.form.isShow +
          "&inActiveStatus=" + this.form.inActiveStatus +
          SortBy + SortDesc +
          "&token=" + this.token,
        "_blank"
      );
    },   
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PROMOTION.SECTION"), route: "/Promotion" },
      { title: this.$t("MENU.PROMOTION.SEARCH") },
    ]);
  },
  computed: {
    title() {
      return this.$t("MENU.PROMOTION.SEARCH");
    },
    token() {
      return JwtService.getToken();
    },
    computedStartDateFormatted() {
      return this.formatDate(this.form.startDate);
    },
  },
  watch: {
    "datatable.options": {
      handler() {
        this.getDataFromApi().then((data) => {
          this.datatable.total = data.total;
          this.datatable.items = data.items;
        });
      },
      deep: true,
    },
    "form.startDate": {
      handler() {
        this.form.startDateFormatted = this.formatDate(this.form.startDate);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
